import {
	Table,
	TableCell,
	TableData,
	TableHeader,
	TableRow,
} from "@components/core/Table";

import Marquee from "@components/Marquee";
import {
	AddToCart,
	AddToPlaylist,
	AddToQueue,
	Play,
} from "@components/interaction";
import {
	ArtistNames,
	renderArtistNames,
} from "@components/shared/Artists/ArtistNames";
import LabelLink from "@components/shared/Labels/LabelLink";
import { usePlayerState } from "@lib/context/player";
import { cls } from "@lib/css";
import { dynamicImageUrl } from "@lib/utils/dynamicImageUrl";
import { CartItem } from "@models/Cart";
import { Release } from "@models/release";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import Loader from "./Loader";
import {
	ArtworkAndControls,
	Exclusive,
	ReleaseName,
	TrackNo,
	Wrapper,
} from "./ReleasesTable.style";

interface Props {
	isLoading: boolean;
	releases?: Release[];
	cartReleases?: CartItem<Release>[];
	itemCartActions?: (cartItem: CartItem<Release>) => JSX.Element;
	showNumbers?: boolean;
	location?: string;
}

const ReleasesTable: React.FC<Props> = ({
	isLoading,
	cartReleases,
	itemCartActions,
	releases = [],
	showNumbers = true,
	location,
}) => {
	const { t } = useTranslation("translation");
	const { currentTrack } = usePlayerState();

	const filteredReleases = cartReleases ?
		cartReleases
			.filter((r) => r.item !== undefined)
			.map((c) => {
				c.item.cart_item_data = c;
				return c.item;
			}) :
		releases;

	const isCartTable = cartReleases !== undefined;

	if (isLoading) {
		return <Loader />;
	}

	return (
		<Wrapper
			className={cls(
				showNumbers ? "numbers" : undefined,
				isCartTable ? "cart-table" : undefined,
			)}
		>
			<Table>
				<TableHeader className="row">
					<TableCell className="controls">&nbsp;</TableCell>
					<TableCell className="title">
						{`${t("Title")} / ${t(
							"Artists",
						)}`}
					</TableCell>
					<TableCell className="label">{t("Label.Title")}</TableCell>
					{!isCartTable && (
						<TableCell className="bpm">BPM</TableCell>
					)}
					<TableCell className="date">{t("ReleaseDate")}</TableCell>
					{isCartTable && (
						<TableCell className={cls("cart-actions")}>
							{t("CartActions")}
						</TableCell>
					)}
					{!isCartTable && <TableCell className="card">&nbsp;</TableCell>}
				</TableHeader>
				<TableData>
					{filteredReleases.map((release, index) => (
						<TableRow
							key={`release-${release.id}`}
							className={cls(
								"row",
								currentTrack &&
								currentTrack.release &&
								currentTrack.release.id === release.id ?
									"current" :
									undefined,
							)}
						>
							<TableCell className="controls">
								<ArtworkAndControls>
									<Link
										href={`/release/${release.slug}/${release.id}`}
										prefetch={false}
										title={release.name}
										className="artwork"
									>
										<Image
											src={dynamicImageUrl({ imageUri: release.image?.uri, size: "md" })}
											alt={release.name}
											width={38}
											height={38}
										/>
										{release.exclusive && (
											<Exclusive>{t("Exclusive")}</Exclusive>
										)}
									</Link>
									{showNumbers && <TrackNo data-testid="track-number">{index + 1}</TrackNo>}
									<span className="fade">
										<Play releaseId={release.id} />
									</span>
									<span className="fade">
										<AddToQueue releaseId={release.id} />
									</span>
									<span className="fade">
										<AddToPlaylist releaseId={release.id} />
									</span>
								</ArtworkAndControls>
							</TableCell>
							<TableCell className="cell title">
								<div className="container">
									<Link
										href={`/release/${release.slug}/${release.id}`}
										prefetch={false}
										title={release.name}
									>
										<Marquee>
											<ReleaseName>{release.name} </ReleaseName>
										</Marquee>
									</Link>
									{release.artists && release.artists.length > 0 && (
										<ArtistNames>
											<Marquee>
												{renderArtistNames(release.artists, { location })}
											</Marquee>
										</ArtistNames>
									)}
								</div>
							</TableCell>
							<TableCell className="cell label">
								<LabelLink label={release.label} location={location} withMarquee />
							</TableCell>
							{!isCartTable && (
								<TableCell className="cell bpm">
									{release.bpm_range.min === release.bpm_range.max ?
										`${release.bpm_range.max} BPM` :
										`${release.bpm_range.min}-${release.bpm_range.max} BPM`}
								</TableCell>
							)}
							<TableCell className="cell date">
								{release.new_release_date ?
									release.new_release_date :
									release.publish_date}
							</TableCell>
							{isCartTable && itemCartActions && release.cart_item_data && (
								<TableCell className="cell cart-actions">
									{itemCartActions(release.cart_item_data)}
								</TableCell>
							)}
							{!isCartTable && (
								<TableCell className="cell card">
									<span className="fade">
										<AddToCart location={location} release={release} />
									</span>
								</TableCell>
							)}
						</TableRow>
					))}
				</TableData>
			</Table>
		</Wrapper>
	);
};

export default ReleasesTable;
